import React from 'react'
import AboutMeComp from '../components/AboutMe'
import Layout from '../components/layout'

function AboutMe(props) {

    return (
        <Layout selected='aboutMe'>
            <AboutMeComp/>
        </Layout>
    )
}

export default AboutMe