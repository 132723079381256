import React from 'react'
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby'

function AboutMe(props) {

    return (
            <section id="one">
            <h2>About Me</h2>
                <header className="major">
                    <Typography variant="h2" style={{paddingBottom: 20}}>
                        Full stack developer who is forever curious and eager to get out of his comfort zone
                        <br />
                    </Typography>
                </header>
                <Typography style={{paddingBottom: 20}}>
                I work at Nokia as a Software Developer. 
                At Nokia, I previously worked on the Analytics team for network management but I am currently
                working on a Workflow application for automating processes or tasks.
                <br />
                <br />
                During my professional career, I have ventured into things out of my regular path
                like entrepreneurship and teaching.
                I have been part of several idea stages to launch startups which have exposed me to things like idea development, idea valuation, pitching and dealing with customers.
                <br />
                <br />
                As a developer, I enjoy using my curious mind and attention to detail to solve problems that have an impact on the world. During my non-working hours, I like to go to hackathons,
                tech meetups/conferences and playing FIFA on Xbox. I am also passionate and involved in 
                charity and volunteer programs that look to improve the quality of education for 
                children in deprived regions
                </Typography>
                <ul className="actions">
                    <li>
                        <Link to='/experience' className="button">Experience</Link>
                    </li>
                </ul>
            </section>
    )
}

export default AboutMe